import * as React from 'react';
import ReactLocalizeRedux = require('react-localize-redux');
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Locale, ProductInfo, TranslateFunction, TranslateModule } from '@its-suite/app-commons';
import { Button, FormErrorMessage, IdentifierHelpers, Select, SelectOption, StringHelpers, TextField, Validator, ValidatorForm, ValidatorTypes } from '@its-suite/form-controls';
import { ownGetTranslate, PasswordField } from '@its-suite/operational-context-commons';
import { FrontendLoaderApplicationState } from '../../redux';
import { PrefixId } from '../../utils';

import * as styles from './Login.scss';

export interface LoginFormProps {
    loginErrorMessage: string;
    login(username: string, password: string): Promise<void>;
    resetError(): void;
}

interface LoginFormStoreProps {
    translate: TranslateFunction;
    currentLanguage: string;
    availableLanguages: ReactLocalizeRedux.Language[];
    locale: ReactLocalizeRedux.LocaleState;
}

interface LoginFormStoreActionProps {
    changeLanguage(languageCode: string): ReactLocalizeRedux.SetActiveLanguageAction;
    updateTranslateFunction(translate: TranslateFunction): TranslateModule.TranslateAction;
}

type LoginFormAllProps = LoginFormProps & LoginFormStoreProps & LoginFormStoreActionProps;

interface LoginFormState {
    userName: string;
    password: string;
    lang: string;
    loading: boolean;
    errorMessage: string;
}

class LoginFormInternal extends React.Component<LoginFormAllProps, LoginFormState> {

    private readonly prefixId: string = PrefixId.Login;

    constructor(props: LoginFormAllProps) {
        super(props);

        this.state = {
            userName: '',
            password: '',
            lang: this.props.currentLanguage,
            loading: false,
            errorMessage: props.loginErrorMessage
        };
    }

    componentDidUpdate(prevProps: Readonly<LoginFormAllProps>): void {
        if (prevProps.locale.languages !== this.props.locale.languages) {
            this.props.updateTranslateFunction(ownGetTranslate(this.props.locale));
        }

        if (this.props.loginErrorMessage !== prevProps.loginErrorMessage) {
            this.setState({ errorMessage: this.props.loginErrorMessage });
        }
    }

    render = (): React.ReactNode => {
        let requiredValidator: Validator[] = [];
        requiredValidator.push(new Validator({ validatorType: ValidatorTypes.Required, message: this.props.translate('ErrorRequiredField') }));

        let langs: SelectOption[] = [];
        this.props.availableLanguages.forEach(l => langs.push({ value: l.code, text: l.name }));
        langs.sort((opt1, opt2) => StringHelpers.compareStrings(opt1.text as string, opt2.text as string));

        return (
            <ValidatorForm onSubmit={this.submitLogin}>
                <div className={styles.panelRowHeader}>
                    <ProductInfo productVersion={process.env.APP_VERSION} />
                </div>
                <div className={styles.panelRow}>
                    <span className={styles.title}>{this.props.translate('Welcome')}</span>
                </div>
                {this.state.errorMessage && this.renderErrorMessage()}
                <div className={styles.gridForm}>
                    <div>
                        <TextField
                            autoFocus={true}
                            className={styles.inputControl}
                            name={StringHelpers.nameof<LoginFormState>('userName')}
                            label={this.props.translate('User')}
                            value={this.state.userName}
                            onChange={this.onTextFieldChange}
                            onFocus={this.clearErrorMessage}
                            autoComplete='username'
                            validators={requiredValidator}
                            id={IdentifierHelpers.createId(this.prefixId, IdentifierHelpers.IdForElement.TextField, ['usr'])}
                        />
                    </div>
                    <div>
                        <PasswordField
                            prefixId={this.prefixId}
                            name={StringHelpers.nameof<LoginFormState>('password')}
                            value={this.state.password}
                            label={this.props.translate('Password')}
                            onChange={this.onTextFieldChange}
                            onFocus={this.clearErrorMessage}
                            autoComplete='current-password'
                            className={styles.inputControl}
                            validators={requiredValidator}
                        />
                    </div>
                    <div>
                        <Select
                            className={styles.inputControl}
                            onChange={this.onLanguageChange}
                            value={this.state.lang}
                            items={langs}
                            id={IdentifierHelpers.createId(this.prefixId, IdentifierHelpers.IdForElement.Select, ['lng'])}
                        />
                    </div>
                    <div>
                        <Button
                            className={styles.buttonControl}
                            type={'submit'}
                            value={this.props.translate('Login')}
                            disabled={this.state.loading}
                            id={IdentifierHelpers.createId(this.prefixId, IdentifierHelpers.IdForElement.Button, ['subm'])}
                        />
                    </div>
                </div>
            </ValidatorForm >
        );
    }

    private renderErrorMessage = (): JSX.Element => (
        <FormErrorMessage
            message={this.state.errorMessage}
            className={styles.errorMessage}
        />
    )

    private onTextFieldChange = (e: React.FormEvent<HTMLInputElement>): void => {
        let eventTarget: HTMLInputElement = e.target as HTMLInputElement;
        this.setState({ ...this.state, [eventTarget.name]: eventTarget.value });
    }

    private onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        let newLang: string = e.target.value;
        this.setState({ lang: newLang });
        this.props.changeLanguage(newLang);
        Locale.set(newLang);
    }

    private submitLogin = async (): Promise<void> => {
        this.setState({ loading: true });
        await this.props.login(this.state.userName, this.state.password);
        this.setState({ loading: false });
    }

    private clearErrorMessage = (): void => {
        this.setState({ errorMessage: '' });
        this.props.resetError();
    }
}

function mapStateToProps(state: FrontendLoaderApplicationState): LoginFormStoreProps {
    return ({
        translate: state.translate,
        currentLanguage: ReactLocalizeRedux.getActiveLanguage(state.locale).code,
        availableLanguages: ReactLocalizeRedux.getLanguages(state.locale),
        locale: state.locale
    });
}

function mapDispatchToProps(dispatch: Dispatch): LoginFormStoreActionProps {
    return bindActionCreators({
        changeLanguage: ReactLocalizeRedux.setActiveLanguage,
        updateTranslateFunction: TranslateModule.actionCreators.updateTranslateFunction
    }, dispatch);
}

// tslint:disable-next-line: variable-name
export const LoginForm: React.ComponentClass<LoginFormProps> =
    connect<LoginFormStoreProps, LoginFormStoreActionProps>(mapStateToProps, mapDispatchToProps)(LoginFormInternal);
