// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/CustomIconFont.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/CustomIconFont.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/CustomIconFont.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/CustomIconFont.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"CustomIconFont\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"svg\");font-weight:normal;font-style:normal}", "",{"version":3,"sources":["webpack://./font/iconFont.scss"],"names":[],"mappings":"AAAA,WACI,4BAAA,CACA,mOACI,CAIJ,kBAAA,CACA,iBAAA","sourcesContent":["@font-face {\r\n    font-family: 'CustomIconFont';\r\n    src:\r\n        url(\"../assets/fonts/CustomIconFont.woff2\") format(\"woff2\"),\r\n        url('../assets/fonts/CustomIconFont.woff') format('woff'),\r\n        url('../assets/fonts/CustomIconFont.ttf') format('truetype'),\r\n        url('../assets/fonts/CustomIconFont.svg') format('svg');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
