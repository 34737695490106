import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    JwtManager, LoginClientInterface, JwtTokenPayload,
    ExceptionResponseInfo, OperationalContextClientFactory
} from '@its-suite/operational-context-client';

import { TranslateFunction, Hash, Constants } from '@its-suite/app-commons';
import { getExceptionMessage, getExceptionResponseInfo, SessionModule } from '@its-suite/operational-context-commons';
import { FrontendLoaderApplicationState } from '../../redux';
import { UpdatePasswordLoginForm } from './UpdatePasswordLoginForm';
import { LoginForm } from './LoginForm';

import * as styles from './Login.scss';

export interface LoginProps {
    onUserLoggedIn(): void;
}

interface LoginStoreProps {
    translate: TranslateFunction;
}

interface LoginStoreActionProps {
    setSession(payload: JwtTokenPayload): SessionModule.SetSessionAction;
}

interface LoginState {
    username: string;
    showUpdatePasswordForm: boolean;
    errorMessage: string;
}

export type LoginAllProps = LoginStoreActionProps & LoginStoreProps & LoginProps;

export class Login extends React.Component<LoginAllProps, LoginState> {

    private readonly jwtManager: JwtManager = new JwtManager();

    constructor(props: LoginAllProps) {
        super(props);

        this.state = {
            showUpdatePasswordForm: false,
            username: '',
            errorMessage: ''
        };
    }

    render(): JSX.Element {
        return (
            <div className={styles.loginContainer}>
                <div className={styles.panelLogin}>
                    {this.state.showUpdatePasswordForm ? this.renderUpdatePasswordForm() : this.renderLoginForm()}
                </div>
            </div>
        );
    }

    private login = async (username: string, password: string): Promise<void> => {
        let hashedPassword: string = Hash.CreateSha256Hash(password);
        let client: LoginClientInterface = OperationalContextClientFactory.getLoginClient();
        try {
            let token: string = await client.login(username, hashedPassword);
            let payload: JwtTokenPayload = this.jwtManager.save(token);
            this.props.setSession(payload);
            this.props.onUserLoggedIn();
        } catch (error) {
            let errorMessage: string = this.props.translate('ErrorLogin');
            let showUpdatePasswordForm: boolean = false;
            const errorResponse: ExceptionResponseInfo = getExceptionResponseInfo(error.response);
            if (errorResponse) {
                const exceptionType: string = errorResponse.exceptionType;
                if (exceptionType === Constants.EXCEPTIONS.lockedAccountException.exceptionName) {
                    errorMessage = getExceptionMessage(this.props.translate, error.response);
                } else if (exceptionType === Constants.EXCEPTIONS.expiredPasswordException.exceptionName) {
                    errorMessage = '';
                    showUpdatePasswordForm = true;
                }
            }

            this.setState({ errorMessage: errorMessage, showUpdatePasswordForm: showUpdatePasswordForm, username: username });
        }
    }

    private renderUpdatePasswordForm = (): JSX.Element => (
        <UpdatePasswordLoginForm
            username={this.state.username}
            login={this.login}
            loginErrorMessage={this.state.errorMessage}
            resetError={this.onResetError}
        />
    )

    private renderLoginForm = (): JSX.Element => (
        <LoginForm
            login={this.login}
            loginErrorMessage={this.state.errorMessage}
            resetError={this.onResetError}
        />
    )

    private onResetError = (): void => {
        this.setState({ errorMessage: '' });
    }

}

function mapStateToProps(state: FrontendLoaderApplicationState): LoginStoreProps {
    return {
        translate: state.translate
    };
}

function mapDispatchToProps(dispatch: Dispatch): LoginStoreActionProps {
    return bindActionCreators({
        setSession: SessionModule.actionCreators.setSession,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
