import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { StylesProvider } from '@material-ui/styles';
import { Locale, AppStatusModel, ApplicationRoutes, ErrorBoundaryContainer } from '@its-suite/app-commons';
import { JwtManager } from '@its-suite/operational-context-client';
import { SagaActions as OcCommonsSagaActions, Logout, ErrorPageInternalError } from '@its-suite/operational-context-commons';
import { defaultTheme, MuiThemeProvider, createMuiTheme, CircularProgress, Paper, PickersUtilsProvider, Theme } from '@its-suite/form-controls';
import { Login } from '../Login';
import { AppSelection } from '../AppSelection';
import { FrontendLoaderApplicationState, SagaActions } from '../../redux';

import * as config from '../../assets/config.json';
import * as styles from './Root.scss';

interface RootStoreProps {
    appStatus: AppStatusModel;
}

interface RootStoreActionProps {
    triggerConfigureAppSaga(configUri: string): SagaActions.TriggerConfigureAppSagaAction;
    triggerRenewTokenSaga(): OcCommonsSagaActions.TriggerRenewTokenSagaAction;
}

type RootAllProps = RootStoreProps & RootStoreActionProps;

class Root extends React.Component<RootAllProps> {

    private readonly theme: Theme = createMuiTheme(defaultTheme);
    private readonly jwtManager: JwtManager = new JwtManager();
    private readonly renewTokenTimeout: number = 720000; // 12 minutes: 12 * 60 * 1000
    private timerHandler: number = 0;

    constructor(props: RootAllProps) {
        super(props);
    }

    componentDidMount = (): void => {
        this.props.triggerConfigureAppSaga(`${config}`);
        this.timerHandler = window.setInterval(this.renewTokenTimerElapsed, this.renewTokenTimeout);
    }

    componentWillUnmount = (): void => {
        if (this.timerHandler > 0) {
            window.clearInterval(this.timerHandler);
        }
    }

    renewTokenTimerElapsed = (): void => {
        if (this.jwtManager.getRawToken()) {
            this.props.triggerRenewTokenSaga();
            console.log('Root call to update session in store');
        }
    }

    render = (): JSX.Element => {
        console.log('Root render');
        const locale: string = Locale.get();
        let content: JSX.Element;
        if (this.props.appStatus === AppStatusModel.Loading) {
            content = this.renderLoading();
        }
        else if (this.props.appStatus === AppStatusModel.Ready) {
            content = this.renderComponent();
        }
        else {
            content = this.renderError();
        }

        return (
            <MuiThemeProvider theme={this.theme}>
                <PickersUtilsProvider locale={locale}>
                    <StylesProvider injectFirst={true}>
                        <Paper>
                            {content}
                        </Paper>
                    </StylesProvider>
                </PickersUtilsProvider>
            </MuiThemeProvider>
        );
    }

    renderError = (): JSX.Element => {
        return (
            <HashRouter>
                <ErrorPageInternalError />
            </HashRouter>
        );
    }

    renderLoading = (): JSX.Element => {
        return (
            <div className={styles.errorContainer}>
                <CircularProgress progressSize='fullSize' />
            </div>
        );
    }

    renderComponent = (): JSX.Element => {
        return (
            <HashRouter>
                <Switch>
                    {/* <AuthRoute exact={true} path={ApplicationRoutes.ROOT} component={props => <AppSelection {...props} />} permissions={[]} /> */}
                    {/* <AuthRoute path={ApplicationRoutes.INVALID_PERMISSIONS} component={props => <ErrorPageForbidden {...props} />} permissions={[]} />
                    <Route path={ApplicationRoutes.INVALID_SESSION} component={props => <ErrorPageUnauthorized {...props} />} /> */}
                    <Route path={ApplicationRoutes.LOGOUT} component={props => <Logout {...props} />} />
                    <Route component={this.renderMainRoute} />
                </Switch>
            </HashRouter>
        );
    }

    renderMainRoute = (): JSX.Element => {
        let content: JSX.Element = null;
        if (!this.jwtManager.validateToken()) {
            content = <Login onUserLoggedIn={this.onUserLoggedIn} />;
        }
        else {
            content = <AppSelection />;
        }
        return (
            <ErrorBoundaryContainer>
                {content}
            </ErrorBoundaryContainer>
        );
    }

    onUserLoggedIn = (): void => {
        this.forceUpdate();
    }
}

function mapStateToProps(state: FrontendLoaderApplicationState): RootStoreProps {
    return {
        appStatus: state.appStatus
    };
}

function mapDispatchToProps(dispatch: Dispatch): RootStoreActionProps {
    return bindActionCreators({
        triggerConfigureAppSaga: SagaActions.actionCreators.triggerConfigureAppSaga,
        triggerRenewTokenSaga: OcCommonsSagaActions.actionCreators.triggerRenewTokenSaga
    }, dispatch);
}

export default connect<RootStoreProps, RootStoreActionProps, {}>(mapStateToProps, mapDispatchToProps)(Root);
