import * as React from 'react';
import { Button, FormErrorMessage, IdentifierHelpers, Typography, ValidatorForm } from '@its-suite/form-controls';
import { ProductInfo, TranslateFunction } from '@its-suite/app-commons';
import { OperationalContextClientFactory, SwaggerException, UpdateCredentialRequest, UserClientInterface } from '@its-suite/operational-context-client';
import { FrontendLoaderApplicationState } from '../../redux';
import { UpdatePasswordFields, PasswordLocalizedTextUtil, UpdatePasswordData, getExceptionMessage } from '@its-suite/operational-context-commons';
import { connect } from 'react-redux';
import classnames from 'classnames';

import * as styles from './Login.scss';

export interface UpdatePasswordLoginFormProps {
    username: string;
    loginErrorMessage: string;
    login(username: string, password: string): void;
    resetError(): void;
}

interface UpdatePasswordLoginFormStoreProps {
    translate: TranslateFunction;
}

type UpdatePasswordLoginFormAllProps = UpdatePasswordLoginFormProps & UpdatePasswordLoginFormStoreProps;

export interface UpdatePasswordLoginFormState extends UpdatePasswordData {
    loading: boolean;
    currentPassword: string;
    password: string;
    confirmationPassword: string;
    errorMessage: string;
}

export class UpdatePasswordLoginFormInternal extends React.Component<UpdatePasswordLoginFormAllProps, UpdatePasswordLoginFormState> {

    private readonly prefixId: string = 'upf';

    constructor(props: UpdatePasswordLoginFormAllProps) {
        super(props);

        this.state = {
            loading: false,
            currentPassword: '',
            password: '',
            confirmationPassword: '',
            errorMessage: props.loginErrorMessage
        };
    }

    componentDidUpdate(prevProps: Readonly<UpdatePasswordLoginFormAllProps>): void {
        if (this.props.loginErrorMessage !== prevProps.loginErrorMessage) {
            this.setState({ errorMessage: this.props.loginErrorMessage });
        }
    }

    render = (): React.ReactNode => {
        return (
            <ValidatorForm onSubmit={this.submitPasswordReset}>
                <div className={styles.panelRowHeader}>
                    <ProductInfo productVersion={process.env.APP_VERSION} />
                </div>
                <div>
                    <span className={classnames(styles.title, styles.resetPasswordTitle)}>{this.props.translate('ResetPassword')}</span>
                </div>
                <div className={styles.panelRowSubheader}>
                    <Typography className={styles.panelRowResetPassword} variant='subtitle1'>{this.props.translate('ResetPassword_Subtitle')}</Typography>
                </div>
                {this.state.errorMessage && this.renderErrorMessage()}
                <div className={styles.gridForm}>
                    <UpdatePasswordFields
                        isEnabled={true}
                        currentPasswordValue={this.state.currentPassword}
                        passwordValue={this.state.password}
                        passwordConfirmationValue={this.state.confirmationPassword}
                        username={this.props.username}
                        inputsContainerClassName={[styles.panelRow, styles.panelRowPasswordField]}
                        inputsClassName={[styles.inputControl]}
                        texts={PasswordLocalizedTextUtil.getUpdatePasswordFieldsTexts(this.props.translate)}
                        onChange={this.onFormChange}
                        onFocus={this.resetError}
                    />
                    <div className={styles.panelRow}>
                        <Button
                            className={styles.buttonControl}
                            type={'submit'}
                            value={this.props.translate('Reset')}
                            disabled={this.state.loading}
                            id={IdentifierHelpers.createId(this.prefixId, IdentifierHelpers.IdForElement.Button, ['subm'])}
                        />
                    </div>
                </div>
            </ValidatorForm>
        );
    }

    private renderErrorMessage = (): JSX.Element => (
        <FormErrorMessage
            message={this.state.errorMessage}
            className={styles.errorMessage}
        />
    )

    private onFormChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const inputName: string = evt.target.name;
        const inputValue: string = evt.target.value;
        this.setState((prevState: UpdatePasswordLoginFormState) => ({ ...prevState, [inputName]: inputValue }));
    }

    private submitPasswordReset = async (): Promise<void> => {
        this.setState({ loading: true });
        const updatePasswordRequest: UpdateCredentialRequest = {
            currentCredential: this.state.currentPassword,
            newCredential: this.state.password
        };

        let userClient: UserClientInterface = OperationalContextClientFactory.getUserClient();
        userClient.updatePassword(this.props.username, updatePasswordRequest)
            .then(() => {
                this.props.login(this.props.username, this.state.password);
            })
            .catch((error: SwaggerException) => {
                this.setState({ errorMessage: getExceptionMessage(this.props.translate, error.response) });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    private resetError = (): void => {
        this.setState({ errorMessage: '' });
        this.props.resetError();
    }
}

function mapStateToProps(state: FrontendLoaderApplicationState): UpdatePasswordLoginFormStoreProps {
    return ({
        translate: state.translate
    });
}

// tslint:disable-next-line: variable-name
export const UpdatePasswordLoginForm: React.ComponentClass<UpdatePasswordLoginFormProps>
    = connect<UpdatePasswordLoginFormStoreProps>(mapStateToProps)(UpdatePasswordLoginFormInternal);
