import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import {
    ResourceClient, ResourceDto, JwtManager, LoginClient, JwtTokenPayload
} from '@its-suite/operational-context-client';
import { DefaultResources, getDefaultResources, SessionModule, ownGetTranslate, CommonConfigureAppSaga } from '@its-suite/operational-context-commons';
import { AppConfig, AppStaticConfigModule, AppStatusModule, TranslateModule, TranslateFunction } from '@its-suite/app-commons';
import * as ReactLocalizeRedux from 'react-localize-redux';
import { FrontendLoaderApplicationState, SagaActions } from '..';

export class ConfigureAppSaga {

    // tslint:disable-next-line:typedef - generator functions can't have return value
    public static * watcher() {
        yield takeLatest(SagaActions.SagaTriggerActionTypes.TRIGGER_CONFIGURE_APP_SAGA, ConfigureAppSaga.worker);
    }

    // tslint:disable-next-line:typedef - generator functions can't have return value
    private static * worker(action: SagaActions.TriggerConfigureAppSagaAction) {
        console.log('ConfigureAppSaga');

        try {
            let staticConfig: AppConfig = yield call(CommonConfigureAppSaga.configureOperationalContextClient, action.payload);

            yield put(AppStaticConfigModule.actionCreators.setAppStaticConfig(staticConfig));
            yield call(ConfigureAppSaga.loadResources);

            let applicationState: FrontendLoaderApplicationState = yield select();
            if (applicationState.appStatus !== AppStatusModule.AppStatusModel.Error) {
                let jwtManager: JwtManager = new JwtManager();
                if (jwtManager.validateToken()) {
                    try {
                        let loginClient: LoginClient = new LoginClient();
                        let token: string = yield call([loginClient, loginClient.renewToken]);

                        let payload: JwtTokenPayload = jwtManager.save(token);

                        yield put(SessionModule.actionCreators.setSession(payload));
                    }
                    catch {
                        jwtManager.forget();
                    }
                }
                yield put(AppStatusModule.actionCreators.setAppStatus(AppStatusModule.AppStatusModel.Ready));
            }
        }
        catch (error) {
            console.log(`Error configuring app: ${error}`);
            yield put(AppStatusModule.actionCreators.setAppStatus(AppStatusModule.AppStatusModel.Error));
        }
    }

    // tslint:disable-next-line:typedef - generator functions can't have return value
    private static * loadResources() {
        try {
            let resourceClient: ResourceClient = new ResourceClient();
            let [resources, invariantResources]: [ResourceDto[], { [key: string]: string; }] =
                yield all([
                    call([resourceClient, resourceClient.getAllResourcesFiles], true, false),
                    call([resourceClient, resourceClient.getAllInvariantResources])
                ]);

            yield call(ConfigureAppSaga.storeDefaultResources, resources, invariantResources);
        }
        catch {
            console.log('Error loading resources');
            yield put(AppStatusModule.actionCreators.setAppStatus(AppStatusModule.AppStatusModel.Error));
        }
    }

    /** Stores the list of languages and initializes React localize redux */
    // tslint:disable-next-line: typedef - generator functions can't have return value
    private static * storeDefaultResources(resources: ResourceDto[], invariantResources: { [key: string]: string; }) {
        try {
            let defaultResources: DefaultResources = getDefaultResources(resources, invariantResources);

            yield put(ReactLocalizeRedux.initialize(defaultResources.languages, defaultResources.options));

            for (let l of resources) {
                let library: ReactLocalizeRedux.SingleLanguageTranslation = {};
                Object.keys(l.stringLibrary).forEach(key => {
                    Object.assign(library, { [`${l.fileName}-${key}`]: l.stringLibrary[key] });
                });
                Object.keys(invariantResources).forEach(key => {
                    Object.assign(library, { [`DoNotTranslate-${key}`]: invariantResources[key] });
                });
                yield put(ReactLocalizeRedux.addTranslationForLanguage(library, l.locale));
            }

            const state: FrontendLoaderApplicationState = yield select();
            let translate: TranslateFunction = yield call(ownGetTranslate, state.locale);

            yield put(TranslateModule.actionCreators.updateTranslateFunction(translate));
        }
        catch {
            console.log('Error storing loaded resources using react-localize-redux');
            yield put(AppStatusModule.actionCreators.setAppStatus(AppStatusModule.AppStatusModel.Error));
        }
    }
}
