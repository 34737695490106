export enum SagaTriggerActionTypes {
    TRIGGER_CONFIGURE_APP_SAGA = 'TRIGGER_CONFIGURE_APP_SAGA'
}

export interface TriggerConfigureAppSagaAction {
    type: SagaTriggerActionTypes.TRIGGER_CONFIGURE_APP_SAGA;
    payload: string;
}

export type SagaTriggerAction = TriggerConfigureAppSagaAction;

export const actionCreators = {
    triggerConfigureAppSaga: (configUri: string): TriggerConfigureAppSagaAction => ({
        type: SagaTriggerActionTypes.TRIGGER_CONFIGURE_APP_SAGA,
        payload: configUri
    })
};
