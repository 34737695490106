import { SessionApplicationState, sessionReducers } from '@its-suite/operational-context-commons';
import { ApplicationState, reducers as commonReducers } from '@its-suite/app-commons';
import { ReducersMapObject } from 'redux';

export * from './actions';
export * from './sagas';

export interface FrontendLoaderApplicationState extends ApplicationState, SessionApplicationState {

}

export const reducers: ReducersMapObject<FrontendLoaderApplicationState> = {
    ...commonReducers,
    ...sessionReducers,
};
