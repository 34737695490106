// Make sure new prefixes do not duplicate already existing ones
export enum PrefixId {
    Login = 'lgn',
    ErrorPage = 'err',
    AppSelection = 'aps'
}

export enum SuffixId {
    List = 'l',
    Form = 'f',
    Default = 'd'
}
