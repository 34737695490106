import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FrontendAppDto, FrontendAppClient, SwaggerException } from '@its-suite/operational-context-client';
import { AppBar, AppBarProps } from '@its-suite/operational-context-commons';
import { TranslateFunction, MessagesActions, AppConfig, UrlHelper, SnackbarWrapper } from '@its-suite/app-commons';
import { CircularProgress, IdentifierHelpers } from '@its-suite/form-controls';
import { MenuButton } from '../../components';
import { PrefixId } from '../../utils/Identifiers';
import { FrontendLoaderApplicationState } from '../../redux';

import * as styles from './AppSelection.scss';

const prefixId: string = PrefixId.AppSelection;

interface AppSelectionStoreProps {
    appConfig: AppConfig;
    translate: TranslateFunction;
}

interface AppSelectionStoreActionProps {
    showSnackbar(message: string): MessagesActions.TriggerShowSnackbarErrorMessageAction;
}

interface AppSelectionState {
    loading: boolean;
    apps: FrontendAppDto[];
}

type AppSelectionAllProps = AppSelectionStoreProps & AppSelectionStoreActionProps;

class AppSelection extends React.Component<AppSelectionAllProps, AppSelectionState> {

    constructor(props: AppSelectionAllProps) {
        super(props);

        this.state = { loading: false, apps: [] };
    }

    componentDidMount(): void {
        let client: FrontendAppClient = new FrontendAppClient();
        client.getAllApps()
            .then(apps => this.setState({ apps: apps }))
            .catch((exception: SwaggerException) => this.props.showSnackbar(exception.response));
    }

    render = (): JSX.Element => {
        if (this.state.loading) {
            return this.renderLoading();
        }
        else {
            return this.renderApps();
        }
    }

    renderLoading = (): JSX.Element => {
        return <CircularProgress />;
    }

    renderApps = (): JSX.Element => {
        // TODO: Recover automatic redirection on single allowed app
        let menuButtons: JSX.Element[] = this.state.apps.map((app, index) => (
            <MenuButton
                key={index}
                appName={app.name}
                text={this.props.translate(app.textKey, app.textResourceFileName)}
                icon={app.icon}
                background={app.color}
                url={UrlHelper.getFullAppUrl(app.url, this.props.appConfig)}
                internalLink={app.embeddedBundle}
                openInNewPage={app.openInNewPage}
                id={IdentifierHelpers.createId(prefixId, IdentifierHelpers.IdForElement.Button, [app.name])}
            />)
        );
        let properties: AppBarProps = {
            menuOptions: null,
            appVersion: process.env.APP_VERSION,
            appName: process.env.APP_NAME,
            appContent: (
                <div className={styles.menuButtonContainer}>
                    <div className={styles.buttonsLayout}>
                        {menuButtons}
                    </div>
                </div>
            )
        };

        return (
            <SnackbarWrapper>
                <AppBar {...properties} />
            </SnackbarWrapper>);
    }
}

function mapStateToProps(state: FrontendLoaderApplicationState): AppSelectionStoreProps {
    return {
        appConfig: state.appStaticConfig,
        translate: state.translate
    };
}

function mapDispatchToProps(dispatch: Dispatch): AppSelectionStoreActionProps {
    return bindActionCreators({ showSnackbar: MessagesActions.actionCreators.triggerShowErrorMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSelection);
