import * as React from 'react';
import { IconTint, StringHelpers, FileTypes, NavigationLink } from '@its-suite/form-controls';

import * as styles from './MenuButton.scss';

const iconSize: number = 65;

interface MenuButtonProps {
    appName: string;
    text: string;
    icon: string;
    background: string;
    url: string;
    id: string;
    internalLink: boolean;
    openInNewPage: boolean;
}

export class MenuButton extends React.Component<MenuButtonProps> {
    render(): JSX.Element {
        return (
            <NavigationLink to={this.props.url} internalLink={this.props.internalLink} openInNewPage={this.props.openInNewPage}>
                <div className={`${styles.imageButton}`} style={{ background: this.props.background }}>
                    <div className={styles.iconContainer}>
                        <IconTint height={iconSize} width={iconSize} src={StringHelpers.getBase64ImgSrc(FileTypes.ImageExtension.svg, this.props.icon)} color={styles.textColor} />
                    </div>
                    <div className={styles.imageButtonText} id={this.props.id}>
                        {this.props.text}
                    </div>
                </div>
            </NavigationLink>
        );
    }
}
