import { createStore, applyMiddleware, combineReducers, Reducer, Store, ReducersMapObject, Action, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AppStatusModule } from '@its-suite/app-commons';
import { History } from 'history';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import * as ReactLocalizeRedux from 'react-localize-redux';
import { FrontendLoaderApplicationState, reducers as appReducers, ConfigureAppSaga } from './redux';
import { RenewTokenSaga, SessionModule, LogoutSaga, ErrorMessagesSaga } from '@its-suite/operational-context-commons';

let allReducers: Reducer<FrontendLoaderApplicationState>;
const DEVELOPMENT: string = 'development';

export default function configureStore(history: History, initialState?: FrontendLoaderApplicationState): Store<FrontendLoaderApplicationState> {

    // Build saga and other middleware. These are functions that can process the actions before they reach the store.
    const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

    const env: string = process.env.NODE_ENV;
    const appliedMiddleware: StoreEnhancer = applyMiddleware(sagaMiddleware);
    let enhancer: StoreEnhancer;
    if (env === DEVELOPMENT) {
        // If devTools is installed, connect to it
        // tslint:disable-next-line: typedef
        const composeEnhancers = composeWithDevTools({});
        enhancer = composeEnhancers(appliedMiddleware);
    } else {
        enhancer = appliedMiddleware;
    }

    // Combine all reducers and instantiate the app-wide store instance
    allReducers = buildRootReducer(appReducers);

    let store: Store<FrontendLoaderApplicationState> = createStore(rootReducer, initialState, enhancer);

    initializeSagaWatchers(sagaMiddleware);
    return store;
}

function initializeSagaWatchers(sagaMiddleware: SagaMiddleware): void {
    sagaMiddleware.run(ConfigureAppSaga.watcher);
    sagaMiddleware.run(RenewTokenSaga.watcher);
    sagaMiddleware.run(LogoutSaga.watcher);
    sagaMiddleware.run(ErrorMessagesSaga.watcher);
}

function buildRootReducer(reducerObjects: ReducersMapObject<FrontendLoaderApplicationState>): Reducer<FrontendLoaderApplicationState> {
    return combineReducers<FrontendLoaderApplicationState>({
        ...reducerObjects,
        locale: ReactLocalizeRedux.localeReducer
    });
}

// Clean ApplicationState fields that should not be in the new session
const rootReducer: Reducer<FrontendLoaderApplicationState> = (state: FrontendLoaderApplicationState, action: Action): FrontendLoaderApplicationState => {
    if (action.type === SessionModule.SessionActionTypes.AFTER_LOGOUT) {
        let appStatusNewState: AppStatusModule.AppStatusModel = state.appStatus === AppStatusModule.AppStatusModel.Error ?
            AppStatusModule.AppStatusModel.Error : AppStatusModule.AppStatusModel.Ready;
        let undefinedState: FrontendLoaderApplicationState;
        state = {
            ...undefinedState,
            appStatus: appStatusNewState,
            appStaticConfig: state.appStaticConfig,
            locale: state.locale,
            translate: state.translate
        };
        return state;
    }

    return allReducers(state, action);
};
